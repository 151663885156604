import {useEffect} from 'react';
import {useRouter} from 'next/router';
import NextLink from 'next/link';
import {useIntl} from 'react-intl';
import {UrlObject} from 'url';

import {getCountry, getLanguage} from '@eksab/util/helpers';
import {useTranslate} from '@eksab/i18n';
import {useAccessToken, useLogout, useMeQuery} from '@eksab/hooks';

import {
  Avatar,
  Box,
  Text,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Link,
  Spacer,
  useToast,
  ComponentWithAs,
  IconProps,
} from '@chakra-ui/react';
import {StarIcon} from '@chakra-ui/icons';
import {ProfileIcon} from '@eksab/assets/icons/ProfileIcon';
import {CoinsIcon} from '@eksab/assets/icons/CoinsIcon';
import {AboutUsIcon} from '@eksab/assets/icons/AboutUsIcon';
import {StoreIcon} from '@eksab/assets/icons/StoreIcon';
import {EarnCoinsIcon} from '@eksab/assets/icons/EarnCoinsIcon';
import {LogoutIcon} from '@eksab/assets/icons/LogoutIcon';
import {NewsIcon} from '@eksab/assets/icons/NewsIcon';
import {TermsAndConditionsIcon} from '@eksab/assets/icons/TermsAndConditionsIcon';

import {useOpenInApp} from './OpenInApp';

type HamburgerMenuProps = {
  isOpen: boolean;
  closeMenu: () => void;
};

export function HamburgerMenu({isOpen, closeMenu}: HamburgerMenuProps) {
  const router = useRouter();
  const formatNumber = useIntl().formatNumber;
  const toast = useToast();
  const isLoggedIn = useAccessToken().isSuccess;
  const t = useTranslate();
  const me = useMeQuery().data;
  const {logout} = useLogout();
  const {setShowOpenInApp} = useOpenInApp();

  const storeRoute = '/store';

  useEffect(() => {
    setShowOpenInApp(!isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onMenuItemClick = (action?: () => void) => {
    closeMenu();
    action?.();
  };

  const onLogout = async () => {
    await logout();
    toast({title: t('auth.logout.success'), status: 'info'});
  };

  return (
    <Drawer size="full" isOpen={isOpen} onClose={closeMenu} placement="start">
      <DrawerContent flexDir="column" bgColor="black" display={['flex', null, null, 'none']}>
        <DrawerHeader bg={me ? 'green.400' : 'black'} pt="6" pb="5" px="4" minHeight="100px">
          {me && (
            <Flex
              align="center"
              gap="3"
              w="fit-content"
              me="5"
              cursor="pointer"
              onClick={() => onMenuItemClick(() => router.push('/profile'))}
            >
              <Avatar
                src={me.photo?.thumb ?? me.photo?.original}
                bgColor="white"
                boxSize="14"
                icon={<ProfileIcon outlined={false} color="gray.400" width="100%" height="100%" />}
              />

              <Box>
                <Text fontSize="lg" fontWeight="900" color="white" mb="1" lineHeight="1">
                  {me?.name}
                </Text>

                <Flex align="center">
                  <CoinsIcon me="1" height="3" width="4.5" />

                  <Text fontSize="sm" color="white" fontWeight="800">
                    {formatNumber(me?.coins)}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          )}

          <DrawerCloseButton color="white" top="9" />
        </DrawerHeader>

        <DrawerBody display="flex" flexDir="column" gap="4" pt="8">
          <MenuItem title={t('app.about-us')} icon={AboutUsIcon} href="/about-us" onClick={onMenuItemClick} />

          <MenuItem
            title={t('app.coins')}
            icon={StoreIcon}
            href={isLoggedIn ? storeRoute : {pathname: '/login', query: {from: encodeURIComponent(storeRoute)}}}
            onClick={onMenuItemClick}
          />

          <MenuItem title={t('app.actions.earn-coins')} icon={EarnCoinsIcon} href="/earn-coins" onClick={closeMenu} />

          <MenuItem title={t('app.news')} icon={NewsIcon} href="/news" onClick={onMenuItemClick} />

          <MenuItem title={t('partners')} icon={StarIcon} href="/partners" onClick={onMenuItemClick} />

          <MenuItem
            title={t('terms-and-conditions')}
            icon={TermsAndConditionsIcon}
            href="/terms-and-conditions"
            onClick={onMenuItemClick}
          />

          {me && (
            <MenuItem title={t('app.actions.logout')} icon={LogoutIcon} onClick={() => onMenuItemClick(onLogout)} />
          )}

          <Flex mt="1" justify="space-between" align="center" borderTopWidth="1px" borderColor="whiteAlpha.500" pt="8">
            <Text color="gray.500">{t('app.actions.choose-language')}</Text>

            <LanguageSwitch onChange={closeMenu} />
          </Flex>
        </DrawerBody>
      </DrawerContent>

      <Spacer />
    </Drawer>
  );
}

type MenuItemProps = {
  title: string;
  icon: ComponentWithAs<'svg', IconProps>;
  href?: UrlObject | string;
  onClick: () => void;
};

function MenuItem({title, href, icon: Icon, onClick}: MenuItemProps) {
  const {push} = useRouter();

  const onMenuItemClick = () => {
    onClick();
    href && push(href);
  };

  return (
    <Flex
      align="center"
      gap="6"
      bgColor="#34343466"
      height="clamp(60px, 10vh, 100px)"
      rounded="md"
      px="6"
      color="gray.500"
      _active={{color: 'green.400'}}
      cursor="pointer"
      onClick={onMenuItemClick}
    >
      <Icon boxSize="8" color="inherit" />

      {href ? (
        <NextLink href={href} passHref legacyBehavior>
          <Link fontWeight="md" fontSize="md" color="inherit" _hover={{}}>
            {title}
          </Link>
        </NextLink>
      ) : (
        <Text fontWeight="md" fontSize="md" color="inherit">
          {title}
        </Text>
      )}
    </Flex>
  );
}

function LanguageSwitch({onChange}: {onChange: () => void}) {
  const {pathname, query, locale, push} = useRouter();

  const onLanguageChange = (lang: Language) => () => {
    if (lang === getLanguage(locale)) return;

    push({pathname: pathname, query}, undefined, {locale: `${lang}-${getCountry(locale)}`});
    onChange();
  };

  return (
    <ButtonGroup
      isAttached
      variant="unstyled"
      borderWidth="2px"
      borderColor="gray.800"
      bgColor="gray.800"
      height={10}
      borderRadius="sm"
      overflow="hidden"
    >
      <Button
        lang="en"
        borderRadius={0}
        height={9}
        w="12"
        color="gray.500"
        isActive={getLanguage(locale) === 'en'}
        _active={{bgColor: 'green.400', color: 'gray.800'}}
        onClick={onLanguageChange('en')}
      >
        AB
      </Button>

      <Button
        lang="ar"
        borderRadius={0}
        height={9}
        w="12"
        color="gray.500"
        isActive={getLanguage(locale) === 'ar'}
        _active={{bgColor: 'green.400', color: 'gray.800'}}
        onClick={onLanguageChange('ar')}
      >
        أب
      </Button>
    </ButtonGroup>
  );
}
